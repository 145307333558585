
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Cartorio } from '@/core/models/imobiliaria';
import { CartorioService } from '@/core/services/imobiliaria';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';

@Component
export default class ListaCartorio extends mixins(Vue,ListPage) {

  item = new Cartorio();
  service = new CartorioService();

  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  titulo: string = 'Cartório';
  subTitulo: string = 'Lista dos Cartórios para o uso no sistema';
 
  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Municipio', value: 'municipio.nome' },
    { text: 'Estado', value: 'estado.uf' }
  ];
 
  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;
 
    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined,  'Municipio, Estado').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  }
  
  Novo(){
    this.item = new Cartorio();
    this.dialogCadastro = true;
  }
  
  mounted(){
    //this.Atualizar();
  }
}
 
